import styled from '@emotion/styled';
import { useState } from 'react';
import { useParams } from 'react-router';

import Box from 'components/atoms/Box';
import { AlignCenter } from 'components/atoms/Flex/Flex';
import { Link } from 'components/atoms/Link/Link';
import Span from 'components/atoms/Span/Span';
import { Comparison } from 'components/organisms/ProjectVersion/VersionsChooser';
import { ProjectVersionButton } from 'components/organisms/ProjectVersion/components/Footer/ProjectVersionButton';
import { useXccCompareContext } from 'hooks/XccCompareContextProvider';
import { XccCompareWithoutCollaborationSpaceId } from 'hooks/useXccCompareService';
import { DesignType } from 'hooks/xccCompareQuery';
import { ReactComponent as CloseIcon } from 'images/cmdClose16.svg';
import { ReactComponent as Warning } from 'images/indicatorWarning16.svg';
import { ReactComponent as Info } from 'images/information2.svg';
import { useUserDataContext } from 'providers/currentUser/UserDataProvider';
import { ProjectRevision } from 'types/project';

type OnRemove = (version: ProjectRevision) => void;

interface Props {
    comparison: Comparison;
    onRemove: OnRemove;
    onClose: () => void;
}

const ComparedVersion = ({
    version,
    onRemove,
}: {
    version?: ProjectRevision;
    onRemove: OnRemove;
}) => {
    return version ? (
        <>
            <Span css={{ fontWeight: 600 }}>Version {version.versionNumber}</Span>
            <ProjectVersionButton
                variant={'text'}
                onClick={() => {
                    onRemove(version);
                }}
            >
                Remove
            </ProjectVersionButton>
        </>
    ) : (
        <Span css={({ colors }) => ({ color: colors.disabledText })}>
            Add one more version&nbsp;
        </Span>
    );
};

const Close = styled(CloseIcon)({
    height: '8px',
});

const NoCacheWarning = ({
    firstVersion,
    secondVersion,
    processingCallback,
}: {
    firstVersion: ProjectRevision | undefined | false;
    secondVersion: ProjectRevision | undefined | false;
    processingCallback: () => void;
}) => {
    const getUncachedVersions = () => {
        if (firstVersion && secondVersion) {
            return `${firstVersion.versionNumber} & ${secondVersion.versionNumber} haven't`;
        } else if (firstVersion) {
            return `${firstVersion.versionNumber} hasn't`;
        } else if (secondVersion) {
            return `${secondVersion.versionNumber} hasn't`;
        } else {
            throw new Error('Unexpected version state');
        }
    };

    return (
        <Box>
            <Box css={({ colors }) => ({ color: colors.textArea.warning.border })}>
                <Span>Version </Span>
                {getUncachedVersions()}
                <Span>
                    {' '}
                    been processed yet and can’t be compared now. We’ll let you know when it’s
                    ready. It usually takes a few minutes. We can let you know when the project is
                    ready to open.
                </Span>
                <Link
                    css={({ colors }) => ({
                        color: colors.actionText,
                        textDecoration: 'none',
                        paddingLeft: '4px',
                    })}
                    onClick={processingCallback}
                >
                    Let me know
                </Link>
            </Box>
        </Box>
    );
};

const CannotCompareWarning = ({ version }: { version: ProjectRevision }) => {
    return (
        <AlignCenter
            css={({ colors }) => ({
                color: colors.textArea.warning.border,
                height: '16px',
                boxSizing: 'border-box',
                fontSize: '10px',
            })}
        >{`Version ${version.versionNumber} has no schematic or layout in the design for comparison. Both versions need the same type`}</AlignCenter>
    );
};

const LongWaitMessage = () => {
    return (
        <AlignCenter>
            {/*Account place for icon space*/}
            <Span css={{ width: '12px' }}></Span>
            <Span
                css={({ colors }) => ({
                    color: colors.disabledText,
                })}
            >
                Checking if versions are ready. This might take up to 30 seconds...
            </Span>
        </AlignCenter>
    );
};

const isSchematicAvailable = (file: string) => file === 'CceSchematic';
const isLayoutAvailable = (file: string) => file === 'CceLayout';

export const QuickComparison = ({ comparison, onRemove, onClose }: Props) => {
    const { firstVersion, secondVersion, projectProps } = comparison;
    const { projectId } = useParams() as { projectId: string };
    const { collaborationSpaceId } = useUserDataContext();
    const [isLongEnough, setIsLongEnough] = useState(false);

    const [mode, setMode] = useState<DesignType>(DesignType.SCHEMATIC);

    const { addRevisions, isCachedInternal, isNotInCache, populateCache, isProcessing } =
        useXccCompareContext();

    const isDisabled = (compareFc: (file: string) => boolean): boolean =>
        !(
            firstVersion &&
            secondVersion &&
            firstVersion.relatedFiles.some(compareFc) &&
            secondVersion.relatedFiles.some(compareFc)
        );

    const hasSchematicLayout = (version: ProjectRevision): boolean =>
        version.relatedFiles.some(
            (file: string) => file === 'CceSchematic' || file === 'CceLayout'
        );

    const firstRevisionToCompare: XccCompareWithoutCollaborationSpaceId | undefined =
        firstVersion && {
            version: firstVersion.versionNumber,
            designType: mode,
            projectId,
            projectName: projectProps?.projectName,
        };

    const secondRevisionToCompare: XccCompareWithoutCollaborationSpaceId | undefined =
        secondVersion && {
            version: secondVersion.versionNumber,
            designType: mode,
            projectId,
            projectName: projectProps?.projectName,
        };

    const firstNotInCache = firstRevisionToCompare && isNotInCache(firstRevisionToCompare);
    const secondNotInCache = secondRevisionToCompare && isNotInCache(secondRevisionToCompare);
    const isAnyNotInCache = firstNotInCache || secondNotInCache;

    const isAllProcessing =
        firstRevisionToCompare &&
        secondRevisionToCompare &&
        isProcessing(firstRevisionToCompare) &&
        isProcessing(secondRevisionToCompare);

    const firstIsCachedInternal =
        !firstVersion ||
        (firstVersion &&
            isCachedInternal({
                version: firstVersion.versionNumber,
                designType: mode,
                projectId,
                collaborationSpaceId,
            }));
    const secondIsCachedInternal =
        !secondVersion ||
        (secondVersion &&
            isCachedInternal({
                version: secondVersion.versionNumber,
                designType: mode,
                projectId,
                collaborationSpaceId,
            }));

    const onClick = (mode: DesignType) => {
        if (!firstVersion?.versionNumber) {
            console.error('First version is undefined');
        }
        if (!secondVersion?.versionNumber) {
            console.error('Second version is undefined');
        }
        if (!firstVersion?.versionNumber || !secondVersion?.versionNumber) {
            return;
        }
        setMode(mode);
        setIsLongEnough(false);
        setTimeout(() => {
            setIsLongEnough(true);
        }, 5000);
        addRevisions(
            {
                version: firstVersion.versionNumber,
                designType: mode,
                projectId,
                collaborationSpaceId,
                projectName: projectProps?.projectName,
            },
            {
                version: secondVersion.versionNumber,
                designType: mode,
                projectId,
                collaborationSpaceId,
                projectName: projectProps?.projectName,
            }
        );
    };

    return (
        <Box css={{ fontSize: '10px', margin: '8px', '& > div': { margin: '8px 0' } }}>
            <AlignCenter
                css={{
                    height: '16px',
                    boxSizing: 'border-box',
                    justifyContent: 'space-between',
                }}
            >
                <AlignCenter>
                    <Info
                        css={({ colors }) => ({
                            stroke: colors.textArea.border,
                            fill: colors.textArea.border,
                            height: '12px',
                        })}
                    />
                    <Span>&nbsp;Quick comparison:&nbsp;</Span>
                    <ComparedVersion
                        version={firstVersion}
                        onRemove={(version) => {
                            setIsLongEnough(false);
                            onRemove(version);
                        }}
                    />
                    <span>vs.&nbsp;</span>
                    <ComparedVersion
                        version={secondVersion}
                        onRemove={(version) => {
                            setIsLongEnough(false);
                            onRemove(version);
                        }}
                    />
                </AlignCenter>
                <AlignCenter
                    css={{
                        '& > span, button': {
                            marginLeft: '4px',
                        },
                    }}
                >
                    <Span css={{ fontWeight: 600 }}>Compare:</Span>
                    <ProjectVersionButton
                        variant={'outlined'}
                        disabled={isDisabled(isSchematicAvailable)}
                        onClick={() => onClick(DesignType.SCHEMATIC)}
                    >
                        Schematic
                    </ProjectVersionButton>
                    <ProjectVersionButton
                        variant={'outlined'}
                        disabled={isDisabled(isLayoutAvailable)}
                        onClick={() => onClick(DesignType.LAYOUT)}
                    >
                        Layout
                    </ProjectVersionButton>
                    <ProjectVersionButton
                        variant={'text'}
                        css={{
                            height: '16px',
                        }}
                        onClick={onClose}
                    >
                        <Close />
                    </ProjectVersionButton>
                </AlignCenter>
            </AlignCenter>
            {isAnyNotInCache && !isAllProcessing && (
                <AlignCenter>
                    <Warning
                        css={{
                            height: '12px',
                            width: '12px',
                            flex: '1 0 auto',
                            marginRight: '4px',
                        }}
                    />
                    <NoCacheWarning
                        firstVersion={firstNotInCache && firstVersion}
                        secondVersion={secondNotInCache && secondVersion}
                        processingCallback={() => {
                            if (!firstVersion?.versionNumber || !secondVersion?.versionNumber) {
                                console.error('First or second version is undefined');
                                return;
                            }
                            populateCache(
                                {
                                    version: firstVersion.versionNumber,
                                    designType: mode,
                                    projectId,
                                    collaborationSpaceId,
                                    projectName: projectProps?.projectName,
                                },
                                {
                                    version: secondVersion.versionNumber,
                                    designType: mode,
                                    projectId,
                                    collaborationSpaceId,
                                    projectName: projectProps?.projectName,
                                }
                            );
                        }}
                    />
                </AlignCenter>
            )}
            {firstVersion && !hasSchematicLayout(firstVersion) && (
                <CannotCompareWarning version={firstVersion} />
            )}
            {secondVersion && !hasSchematicLayout(secondVersion) && (
                <CannotCompareWarning version={secondVersion} />
            )}
            {(!firstIsCachedInternal || !secondIsCachedInternal) && isLongEnough && (
                <LongWaitMessage />
            )}
        </Box>
    );
};

export default QuickComparison;
