import styled from '@emotion/styled';

import Box, { BoxProps } from 'components/atoms/Box';
import { ReactComponent as ErrorExclamation } from 'images/error_exlamation.svg';

const Wrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(2),
}));

const Content = styled(Box)(({ theme }) => ({
    backgroundColor: theme.colors.background,
    borderRadius: 12,
}));

export const Container = styled(Box)(({ theme }) => ({
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    paddingBottom: theme.spacing(2),
}));

export const MessageContainer = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    marginBottom: theme.spacing(4),
}));

export const ErrorTitle = styled.span(({}) => ({
    alignSelf: 'center',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '23px',
    margin: '0px',
}));

export const ErrorMessage = styled.span(({}) => ({
    fontStyle: 'italic',
    lineHeight: '23px',
    fontSize: '18px',
    fontWeight: '400',
    margin: '0px',
}));

export const ErrorStateIcon = styled(ErrorExclamation)(({}) => ({
    width: '48px',
    height: '48px',
    flexShrink: 0,
}));

export interface Props extends BoxProps {
    width?: number;
    height?: number;
}

function BomErrorState({ children, width, height }: Props) {
    return (
        <Wrapper>
            <Content css={{ width, height }}>{children}</Content>
        </Wrapper>
    );
}

export default BomErrorState;
